import Web3Modal from "web3modal";
import "./App.css";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { PublicSaleContractAddress } from "./config";
import PublicSaleAbi from "./abi/PublicSale.json"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Image from "./images/myra_logo.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function App() {
  const [wallet, setWallet] = useState();
  const [chainId, setChainId] = useState();
  const [txHash, setTxHash] = useState();
  const [amountBusd, setAmountBusd] = useState(0);
  const [myra, setMyra] = useState();
  const [refWallet, setRefWallet] = useState("");
  const [proofs, setProofs] = useState([]);

  useEffect(()=>{
    loadAllProofs();
  })

  async function access() {
    //let amount = ethers.utils.parseUnits(100);
    const tokenAddress = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
    let minABI = [
      // transfer
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [
          {
            name: "",
            type: "uint8",
          },
        ],
        payable: false,
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],

        type: "function",
      },
    ];
    if (amountBusd < 1000 || amountBusd > 45000) {
      toast(
        "BUSD Amount should be bigger than 1000 BUSD and less than 45000 BUSD!",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        }
      );
    } else {
      console.log("myra decimal");
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      console.log("myra decimal 1");
      // Get ERC20 Token contract instance
      const contractBusd = new ethers.Contract(tokenAddress, minABI, signer);
      const contractPS = new ethers.Contract(PublicSaleContractAddress, PublicSaleAbi.abi, signer);
      // calculate ERC20 token amount
      const myraDecimal = await contractBusd.decimals();
      console.log("myra decimal", myraDecimal);

      const tokenAmountInEther = ethers.utils.parseUnits(
        amountBusd.toString(),
        myraDecimal
      );
      setWallet(await signer.getAddress());
      setChainId(await signer.getChainId());
      try {
        const datatx = await contractBusd.transfer(
          "0xC01084aD41908403df6Da94a049C7fAEc1e87d72",
          tokenAmountInEther
        );
        await datatx.wait();

         toast("Transfer is success! Now confirm for proof!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

       
      } catch (e) {
        toast("insufficient funds", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      try{
        const transaction = await contractPS.proofBuying(wallet,amountBusd.toString(),refWallet);
        await transaction.wait();
        toast("confirm is success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }catch(e){
        toast(e.toString(), {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }
  }

  async function loadAllProofs() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      PublicSaleContractAddress,
      PublicSaleAbi.abi,
      signer
    );
    const data = await contract.getAllProofs();

    const result = await Promise.all(
      data.map(async (proof) => {

        let item = {
          id: proof.id,
          buyerAddress: proof.buyerAddress,
          amountBusd: proof.amountBusd,
          refAddress: proof.refAddress,
        };
        return item;
      })
    );

    setProofs(result.reverse());
  }

  return (
    <div className="App">
      <div className="intro" id="#intro">
        <div className="logo-1">
          <a href="#intro">
            <img src={Image} alt="" className="logo" />
          </a>
        </div>

        <a href="https://myratoken.com" style={{color:"white",marginTop:"3%", marginRight:"4%", textDecoration:"none"}}>
          <p style={{color:"white", marginRight:"2%", marginTop:"3%"}}> Website
          
          </p>
        </a>
        <a href="https://docs.myratoken.com" style={{color:"white",marginTop:"3%", marginRight:"4%", textDecoration:"none"}}>
          <p style={{color:"white", marginRight:"2%", marginTop:"3%"}}> Docs
        
          </p>
        </a>
        <a href="https://github.com/myratoken/Myra_Whitepaper/blob/main/Myra_Whitepaper.pdf" style={{color:"white",marginTop:"3%", marginRight:"4%", textDecoration:"none"}}>
          <p style={{color:"white", marginRight:"2%", marginTop:"3%"}}> Whitepaper
        
          </p>
        </a>
        <p style={{ color: "red", marginTop: "2%", marginRight:"5%"}}>
          Before buying, please read instructions!
          <a href="#instructions" style={{ color: "red" }}>
            <ArrowDownwardIcon />
          </a>
        </p>
        </div>
       
       


      <div className="info">
        <div className="card-1">
          <div>
            <b>Total Pool</b>
          </div>
          <div>20.833.333,3333 MYR</div>
        </div>
        <div className="card-2">
          <div>
            <b>Total Available MYR to Buy</b>
          </div>
          <div>18.750.000 MYR</div>
        </div>
        <div className="card-3">
          <div>
            <b>Total Ref Rewards</b>
          </div>
          <div>2.083.333,33333 MYR</div>
        </div>
      </div>
      <div className="container">
        <div className="card-info">
          <p style={{ fontFamily: "sans-serif", color: "black" }}>
            <b>Login with Metamask, buy with 25% discount!</b>
          </p>
          <p style={{ fontFamily: "sans-serif", color: "black" }}>
            <b>And give reference to your friend, gain 10% MYR!</b>
          </p>
       
        </div>
        <div className="card">
          <div className="text-amount">
            <p>BUSD</p>
            <input
              className="text-for-tag"
              style={{ borderRadius: "5px", height: "25px" }}
              placeholder="Enter Amount"
              type="text"
              onChange={(e) => setAmountBusd(e.target.value)}
            />
            <p>MYR</p>
            <input
              className="text-for-tag"
              style={{ borderRadius: "5px", height: "25px" }}
              placeholder={amountBusd === 0 ? 0 : amountBusd / 0.0024}
              type="text"
              readOnly
            />
             <input
              className="text-for-tag"
              style={{ borderRadius: "5px", height: "25px", marginTop:"2%"}}
              placeholder="Enter Ref Wallet (If Available)"
              type="text"
              onChange={(e) => setRefWallet(e.target.value)}
            />
          </div>
          <div className="buyButton">
            <button className="buy" onClick={access}>
              <p style={{ fontFamily: "sans-serif" }}>
                <b>Buy</b>
              </p>
            </button>
          </div>
        </div>
        <div className="card-price">
        <p style={{ fontFamily: "sans-serif", color: "darkred" }}><b>IDO Price: $0.0024</b></p>
        <p style={{ fontFamily: "sans-serif", color: "darkred" }}><b>1 BUSD = 416 MYR</b></p>
          <p style={{ fontFamily: "sans-serif", color: "black" }}><b>Listing Price: $0.0032</b></p>
          <p style={{ fontFamily: "sans-serif", color: "black" }}><b>1 BUSD = 312 MYR</b></p>
        </div>
      </div>

      <div className="table-user" style={{color:"white"}}>
        <table style={{marginTop:"2%"}}>
          <thead>
            <tr>
              <th>Wallet Address</th>
              <th>BUSD</th>
              <th>MYR AMOUNT</th>
              <th>Refferal Wallet</th>
            </tr>
          </thead>
          <tbody>
            {proofs.length <= 0 ?   <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              </tr>: proofs.map((proof)=>(
              <tr>
              <td>{proof.buyerAddress}</td>
              <td>{proof.amountBusd.toString()}</td>
              <td>{proof.amountBusd/0.0024}</td>
              <td>{proof.refAddress}</td>
              </tr>
            ))}
            {  }
           
          </tbody>
        </table>
      </div>

      <div className="instructions" id="instructions">
       
        <div className="content">
          <b>
          <p style={{color:"black"}}>INSTRUCTIONS</p>
          <p>MYR Public Sale Event is officially held only on bsclaunch.org and
          ps.myratoken.com.
           Apart from this, do not trust third parties offered
          for sale under the name MYRA and MYR token.</p>
          <p style={{color:"darkred"}}>**MYRA team and MYR token are not responsible for fraudulent transactions occurring on sites other than ps.myratoken.com and bsclaunch.org**</p>
          <p>20% of the MYRs you will
          purchase at a discount in Public Sale will be opened in TGE, the rest
          will be locked for 1 month and then distributed linearly for 2 months.</p>
          <p>20% TGE of discounted MYRs purchased through ps.myratoken.com will be opened on December 5, 2023, together with Bsclaunch. If the date changes, an announcement will be made through official channels, please follow it.</p>
          <p>You can buy MYR worth 500 BUSD and below at bsclaunch.org</p>
          <p>You can buy a minimum of 1000 BUSD worth of MYR and a maximum of 45,000 BUSD worth of MYR through ps.myratoken.com </p>
          <p> If the person you refer also purchases MYR worth
          1000 BUSD, 10% of the total MYR you purchased will be sent as a gift.</p>
          <p style={{color:"darkred"}}>While buying MYR, the person you refer must enter the wallet address from which you bought MYR.<br/> Otherwise you cannot receive reward.</p>
          <p style={{color:"darkred"}}><b>You need to make 2 confirmations (On Metamask);</b><br/> <b>1- Confirm for payment</b><br/><b>2- Confirm for proof(Proof informations will be sent to Smart Contract)</b></p>
          <p style={{color:"darkred"}}><b>Don't try less than $1000! This won't be accepted!</b></p>
          <p>Example:</p>
          <p>If you buy MYR worth 1000 BUSD and If your referral buys MYR
          worth 1000 BUSD. You earn MYR worth 100 BUSD!</p>
          <p>Be sure that you have enough BUSD for payment and you have enough BNB for transaction fee!</p>
          <p>This campaign will continue between November, 21 - November, 26</p>
          <p>If you need more information, write to info@myratoken.com</p>
          </b>
        </div>
      </div>

    
      <ToastContainer />
    </div>
  );
}

export default App;
